import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import {Deferred} from 'ts-deferred';
import {DialogResult, DialogSize} from './dialog.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }


  async show<T, R = any>(componentRef: ComponentType<T> | TemplateRef<T>, size: DialogSize, data?: any, showModal = true): Promise<DialogResult<R>> {

    var config: MatDialogConfig = {};

    this.configureSize(size, config);

    config.data = data;
    config.hasBackdrop = true;

    const dialogRef = this.dialog.open(componentRef, config);



    let deferred = new Deferred<DialogResult<R>>();
    const p = deferred.promise;


    var subscription = dialogRef.afterClosed()
    .subscribe(n=> {
      deferred.resolve(n);
      subscription.unsubscribe();
    });


    return p;



  }

  async showDialog<T, R = any>(componentRef: ComponentType<T> | TemplateRef<T>, config: MatDialogConfig): Promise<DialogResult<R>> {

    const dialogRef = this.dialog.open(componentRef, config);

    let deferred = new Deferred<DialogResult<R>>();
    const p = deferred.promise;


    var subscription = dialogRef.afterClosed()
    .subscribe(n=> {
      deferred.resolve(n);
      subscription.unsubscribe();
    });


    return p;


  }

  async confirm(message: string, title?: string): Promise<boolean> {

    const deferred = new Deferred<boolean>();
    const p = deferred.promise;

    abp.message.confirm(message, title, (result)=>{
        if(result) {
            deferred.resolve(true);
        }else {
            deferred.resolve(false)
        }
    });
    return p;

  }

  info(message: string, title?: string): void {


    abp.message.info(message, title);


  }


  configureSize(size: DialogSize, config: MatDialogConfig) {


    switch (size) {
      case DialogSize.Small:
        config.width = '400px';
        config.height = '400px';
        break;

      case DialogSize.Medium:
        config.width = '540px';
        config.height = '500px';
        break;

      case DialogSize.Large:
        config.width = '900px';
        config.height = '800px';
        break;

      default:
        break;
    }


  }




}
