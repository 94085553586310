<div class="tabs">
    <div class="tab active"
         *ngFor="let tab of tabs; let i = index;"
         [class.active]="tab.isActive"
         [class.with-close-button]="!tab.isDefault"
         [class.with-subtitle]="true"
         (click)="tabClicked(tab)"
         (auxclick)="onTabClicked($event, tab)">
      <span class="title">{{ tab.title }}</span>
      <span class="subtitle">{{ tab.subTitle }}</span>

      <button mat-icon-button (click)="closeTab(tab)" *ngIf="i > 0">
        <mat-icon [inline]="true">close</mat-icon>
      </button>
    </div>
  </div>
