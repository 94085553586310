import { Injectable } from '@angular/core';
import { TreoNavigationItem } from '@treo/components/navigation';
import { PermissionCheckerService } from 'abp-ng2-module';

@Injectable({
    providedIn: 'root'
})
export class NavigationPermisssionService {

    constructor(private permissionChecker: PermissionCheckerService) { }


    setupPermissions(navItems: TreoNavigationItem[]) {

        for (let item of navItems) {
            this.setItemPermissions(item);
        }

    }


    setItemPermissions(navItem: TreoNavigationItem) {

        navItem.disabled = false;

        if (navItem.permission) {




            if (!this.permissionChecker.isGranted(navItem.permission)) {
                navItem.disabled = true;
            }
        }

        if (navItem.children && navItem.children.length > 0) {
            for (let childItem of navItem.children) {
                this.setItemPermissions(childItem);
            }
        }

    }

}
