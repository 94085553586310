import { Injectable } from '@angular/core';
import { DialogService } from '@shared/utilities/dialogs/dialog.service';
import { DialogResult } from '@shared/utilities/dialogs/dialog.model';
import { MediaInfo } from './media.model';
import { ImageSelectorDialog } from './image-selector/image-selector.dialog';
import {ImageUploaderDialog} from "@shared/media/image-uploader/image-uploader.dialog";
import {AddImageAssetResponse} from "@core/app-services/app-services";

@Injectable()
export class MediaSelectorService {

  constructor( private _dialogService: DialogService) {

  }

  async selectImage(): Promise<DialogResult<MediaInfo>> {
    const input: any = '';
    const result = await this._dialogService.showDialog<ImageSelectorDialog, MediaInfo>(ImageSelectorDialog,
        {
            width: '80vw',
            height: '90vh',
            disableClose: true
        });

    return result;
    }

    async uploadImage(): Promise<DialogResult<AddImageAssetResponse>> {
        const input: any = '';
        const result = await this._dialogService.showDialog<ImageUploaderDialog, AddImageAssetResponse>(ImageUploaderDialog,
            {
                width: '30vw',
                height: '50vh',
                disableClose: false
            });

        return result;
    }
}
