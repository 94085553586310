import { Injectable } from '@angular/core';
import { DialogService } from '@shared/utilities/dialogs/dialog.service';
import { MediaApiProxy, MediaFolderDto, ImageMediaDto, SearchFolderImagesRequest, AddMediaFolderRequest, EditMediaFolderRequest } from '@app/core/app-services/content-services';
import { Observable, BehaviorSubject } from 'rxjs';
import { MediaInfo, FolderInfo } from './media.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaService {


  private _folders: BehaviorSubject<MediaFolderDto[]> = new BehaviorSubject<MediaFolderDto[]>([]);

  readonly folders$: Observable<MediaFolderDto[]> = this._folders.asObservable();

  constructor(private _mediaService: MediaApiProxy) {

  }

getFolderHierarchy(): Promise<MediaFolderDto[]> {
    return this._mediaService.getFolderHierarchy().pipe(take(1)).toPromise();
}

getFolderImages(folderId: string | undefined, pageNumber: number | undefined, pageSize: number | undefined): Promise<ImageMediaDto[]> {
    return this._mediaService.getFolderImages(folderId, pageNumber, pageSize).pipe(take(1)).toPromise();
}

searchFolderImages(request: SearchFolderImagesRequest): Observable<ImageMediaDto[]> {
    return this._mediaService.searchFolderImages(request);
}

addMediaFolder(request: AddMediaFolderRequest): Promise<MediaFolderDto[]> {
    return this._mediaService.addMediaFolder(request).pipe(take(1)).toPromise();
}

removeMediaFolder(folderId: string): Promise<boolean>{
    return this._mediaService.removeMediaFolder(folderId).pipe(take(1)).toPromise();
}

updateMediaFolder(request: EditMediaFolderRequest): Promise<MediaFolderDto[]> {
    return this._mediaService.renameMediaFolder(request).pipe(take(1)).toPromise();
}

// async uploadFile(folderId: any): Promise<void> {

//     const result = await this.dialogService.showDialog<UploadDialog, any>(UploadDialog,
//         {
//             width: '60vw',
//             height: '90vh',
//             disableClose: true,
//             data: folderId
//         });

//     if (result.isCompleted) {

//     }
// }


// async createNewFolder(folderId: any, path: string): Promise<FolderInfo> {

//     const result = await this.dialogService.showDialog<CreateFolderDialog, FolderInfo>(CreateFolderDialog, {
//         width: '20vw',
//         height: '30vh',
//         disableClose: true,
//         data: {folderId, path}
//     });

//     if (result.isCompleted) {
//         return result.value;
//     }else {
//         return new FolderInfo();
//     }
// }

// async renameMediaFolder(folderId: string, folder: string): Promise<FolderInfo> {
//     const result = await this.dialogService.showDialog<RenameFolderDialog, FolderInfo>(RenameFolderDialog, {
//         width: '25vw',
//         height: '35vh',
//         disableClose: true,
//         data: {folderId, folder}
//     });

//     if (result.isCompleted) {
//         return result.value;
//     }else {
//         return new FolderInfo();
//     }
// }
}
