export class AppPermissions {
    public static readonly course = {
        students: 'Course.Students',
        activities: 'Course.Activities',
        assignments: 'Course.Assignments',
        subjects: 'Course.Subjects',

    };

    public static readonly subscriptions =  {
        update: "Subscription.Change"
    }

    public static readonly registry =  {
        staff: "Registry.Staff"
    }
}
