import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import {TabItem} from "./tab-item";

export class TabRouteStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  tabs: TabItem[] = [];

  constructor() {

  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) return null;
    if (route.routeConfig.loadChildren || route.routeConfig.children ) return null;
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);

    if (this.allowRetrieveCache(path)) {
      return this.storedRouteHandles.has(path);
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.tabs.find(t => t.url.toLowerCase() === path.toLowerCase())) {
      return true;
    }

    return false;
  }

  shouldReuseRoute(before: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return this.getPath(before) === this.getPath(current);
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void {
    this.storedRouteHandles.set(this.getPath(route), detachedTree);
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    const path = route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/')
      .replace(new RegExp('//', 'g'), '/');
    return path.replace(new RegExp('//', 'g'), '/');
  }

  private allowRetrieveCache(path: string): boolean {
    const tab = this.tabs.find(t => t.url.toLowerCase() === path.toLowerCase());

    return tab !== null && tab !== undefined;
  }

  public removeRoute(path: string) {
    path = path.toLowerCase();

    this.storedRouteHandles.delete(path);
  }

}
