import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TabItem } from '../tab-item';
import {Router} from "@angular/router";
import { TabNavigationService } from '../tab-navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fx-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent implements OnInit {
  @Output() activeTabChange: EventEmitter<TabItem> = new EventEmitter();
  @Output() tabClose: EventEmitter<TabItem> = new EventEmitter();


  tabs: TabItem[];

  constructor(private router: Router,
              private tabService: TabNavigationService,
              private changeDetector: ChangeDetectorRef
              ) {
  }

  ngOnInit() {
    this.tabService.tabs$.pipe(
        untilDestroyed(this)
    ).subscribe(tabs=>{
        this.tabs = tabs;
        this.changeDetector.markForCheck();
    })
  }




  async tabClicked(tab: TabItem): Promise<void> {
    this.tabService.goToTab(tab);

  }

  closeTab(tab: TabItem): void {
    this.tabService.closeTab(tab);
  }

  drop(event: CdkDragDrop<TabItem[]>) {
    moveItemInArray(this.tabs, event.previousIndex, event.currentIndex);
  }

  look($event: MouseEvent) {
    console.log($event);
  }

  onTabClicked(event: MouseEvent, tab: TabItem) {
    if (event.button === 1) {
      this.closeTab(tab);
    }
  }


}

