import {NgModule, ModuleWithProviders} from '@angular/core';
import {AppSessionService} from '@app/core/abp/app-session.service';
import {AppUrlService} from '@app/core/abp/app-url.service';
import {AppAuthService} from '@app/core/abp/app-auth.service';
import {AppRouteGuard} from '@app/core/abp/app-route-guard';


@NgModule({
    providers: []
})
export class AbpCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AbpCoreModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard


            ]
        };
    }
}