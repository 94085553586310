import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouteReuseStrategy } from '@angular/router';
import { TabRouteStrategy } from './tab-route-strategy';



@NgModule({
  declarations: [TabBarComponent],
  imports: [
    CommonModule,
    MatButtonModule,
        MatIconModule,
        DragDropModule
  ],
  exports: [
      TabBarComponent
  ],
  providers: [
    {
        provide: RouteReuseStrategy,
        useClass: TabRouteStrategy,
        deps: []
    },
  ]
})
export class NavigationModule { }
