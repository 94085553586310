import {NgModule, ModuleWithProviders} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AbpHttpInterceptor, RefreshTokenService} from 'abp-ng2-module';
import * as App from './app-services';
import * as Registry from './registry-services';
import * as Content from './content-services';
import { AuthRefreshTokenService } from '@core/auth/auth-refresh-token.service';

@NgModule({
    providers: []
})
export class AppServicesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppServicesModule,
            providers: [
                App.RoleApiProxy,
                App.SessionApiProxy,
                App.TenantApiProxy,
                App.UserApiProxy,
                App.TokenAuthApiProxy,
                App.AccountApiProxy,
                App.ConfigurationApiProxy,
                App.ImagesApiProxy,
                

                // LMS
                Content.CourseApiProxy,
                Content.QuizDesignerApiProxy,
                Content.StudentsApiProxy,
                Content.StudentActivityApiProxy,
                Content.MediaApiProxy,


                Content.ActivityApiProxy,
                Content.LessonApiProxy,
                Content.SubjectApiProxy,
                Content.PresentationActivityApiProxy,
                Content.QuizActivityApiProxy,
                Content.QuizAssignmentApiProxy,
                Content.AssignmentsApiProxy,
                Content.RecordingActivityApiProxy,
                Content.CourseSegmentApiProxy,
                Content.TopicApiProxy,
                Content.CourseClassApiProxy,

                Registry.SubscriptionApiProxy,
                Registry.RegistrationApiProxy,
                Registry.StaffApiProxy,
                { provide: RefreshTokenService, useClass: AuthRefreshTokenService },
                {provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true}
            ]
        };
    }
}
