import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'app/layout/common/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    // Observables
    private _user: BehaviorSubject<User | null>;

    /**
     * Constructor
     *
     *
     */
    constructor(

    )
    {
        // Set the defaults
        this._user = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Setter and getter for user
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


}
