import { ITabItem } from '.';
import { TabInfo } from './tab-info';



export class TabItem implements ITabItem {

    key: string;
    rootPath: string = '';
    url: string = '';
    title: string= '';
    subTitle: string= '';
    isDefault: boolean = false;
    isActive: boolean= false;


    isParentOf(route: string): boolean{

        return false;

    }

    setInfo(title: string, subTitle?: string) {
        this.subTitle = '';
        this.title = title;
        if(subTitle) {
            this.subTitle = subTitle;
        }
    }



}
