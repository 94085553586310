import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AppRouteGuard } from './core/abp/app-route-guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'admin'},

    // Redirect signed in user to the '/example'
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'admin'},

    // Auth routes
    {
        path: '',
        component: EmptyLayoutComponent,
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)}
        ]
    },
    {
        path: '',
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        component: EmptyLayoutComponent,
        children: [
            //{path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },



    // Admin routes
    {
        path       : '',
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [

            // Example
            {path: 'admin', loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule)}

            // 404 & Catch all
            // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
            // {path: '**', redirectTo: '404-not-found'}
        ]
    },

    // Feature Routes
    {
        path       : '',
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [



            {path: 'course-outline', loadChildren: () => import('app/modules/course-outline/course-outline.module').then(m => m.CourseOutlineModule)},
            {path: 'quiz', loadChildren: () => import('app/modules/quiz/quiz.module').then(m => m.QuizModule)},
            {path: 'recording', loadChildren: () => import('app/modules/recording/recording.module').then(m => m.RecordingModule)},
            {path: 'presentation', loadChildren: () => import('app/modules/presentation/presentation.module').then(m => m.PresentationModule)},
            {path: 'students', loadChildren: () => import('app/modules/students/students.module').then(m => m.StudentsModule)},
            {path: 'registration', loadChildren: () => import('app/modules/registration/registration.module').then(m => m.RegistrationModule)},
            {path: 'assignments', loadChildren: () => import('app/modules/assignments/assignments.module').then(m => m.AssignmentsModule)},
            {path: 'quiz-assignment', loadChildren: () => import('app/modules/quiz-assignment/quiz-assignment.module').then(m => m.QuizAssignmentModule)},
            {path: 'student-profile', loadChildren: () => import('app/modules/student-profile/student-profile.module').then(m => m.StudentProfileModule)},
            {path: 'activity', loadChildren: () => import('app/modules/activity/activity.module').then(m => m.ActivityModule)}

        ]
    }
];
