import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, ResolveEnd, Router, RouteReuseStrategy } from '@angular/router';
import { PageInfo } from '@app/core/shell/page-info';
import { ShellService } from '@app/core/shell/shell.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { INavConfig, ITabItem } from './route-data';
import { TabConfig } from './tab-config';
import { TabInfo } from './tab-info';
import { TabItem } from './tab-item';
import { TabRouteStrategy } from './tab-route-strategy';

@Injectable({
    providedIn: 'root'
})

export class TabNavigationService {

    private _tabsSource: BehaviorSubject<TabItem[]>


    get tabs$() {
        return this._tabsSource.asObservable();
    }

    get tabs() {
        return this._tabsSource.value;
    }

    private _defaultTab: TabItem;
    private _activeTab: TabItem;


    constructor(private router: Router,
        private shell: ShellService,
        private routeReuse: RouteReuseStrategy) {

        this._defaultTab = new TabItem();
        this._defaultTab.isDefault = true;
        this._defaultTab.setInfo('Home');



        this._tabsSource = new BehaviorSubject<TabItem[]>([this._defaultTab]);

        this.activateTab(this._defaultTab);


        this.router.events.pipe(
            filter(event => event instanceof ResolveEnd)
        ).subscribe((navEnd: ResolveEnd) => {
            this.onNavigationEnd(navEnd);
        });

        this.shell.titleChanged.subscribe(
            info => {
                this.onInfoChanged(info);
            }
        )
    }



    onNavigationEnd(event: ResolveEnd) {

        event.state


        let exactTab = this.getExistingTab(event.urlAfterRedirects);
        if (exactTab) {
            this.activateTab(exactTab);
            return;
        }

        let tab = this.findTab(event.urlAfterRedirects);

        if (tab.isDefault) {
            tab.url = event.urlAfterRedirects;
        }

        this.activateTab(tab);

    }

    onInfoChanged(info: PageInfo) {

        if (!this._activeTab) {
            return;
        }

        this._activeTab.title = info.title;
        this._activeTab.subTitle = info.subTitle;

        let tabs = this.tabs;
        this._tabsSource.next(tabs);

    }



    registerTab(config: TabConfig): ITabItem {


        var existing = this.getExistingTab(config.url);

        if (existing) {
            return existing;
        }

        let item = new TabItem();
        item.rootPath = config.rootPath;
        item.url = config.url;

        this.addTab(item);
        this.updateRoutingCache();
        return item;

    }

    addTab(item: TabItem) {

        let tabs = [...this._tabsSource.value, item];
        this._tabsSource.next(tabs);

    }


    findTab(url: string): TabItem | undefined {


        let exactMatch = this.tabs.find(n => !n.isDefault && n.url == url);

        if (exactMatch) {
            return exactMatch;
        }

        let parent = this.tabs.find(n => !n.isDefault && url.startsWith(n.rootPath));

        if (parent) return parent;

        return this._defaultTab;


    }

    getExistingTab(url: string): TabItem | undefined {


        let exactMatch = this.tabs.find(n => n.url == url);

        if (exactMatch) {
            return exactMatch;
        }

        return undefined;


    }

    goToTab(tab: TabItem): void {
        this.router.navigateByUrl(tab.url);
    }

    activateTab(tab: TabItem): void {

        this._activeTab = tab;
        const tabs = this.tabs;

        tabs.forEach(n => {
            n.isActive = n.url == tab.url;
        });

        this._tabsSource.next(tabs);




    }

    closeTab(tab: TabItem): void {

        if(tab.isDefault) {
            return;
        }

        const tabs = this.tabs;


        const tabIndex = tabs.findIndex(t => t.url === tab.url);



        if(tabIndex > 0) {

        }

        if (tabIndex === 0) {
            return;
        }


        const previousTab = tabIndex > 0 ? tabs[tabIndex - 1] : this._defaultTab;

        const newTabs = tabs.filter(n=>n.url != tab.url);

        this._tabsSource.next(newTabs);


        this.goToTab(previousTab);

        //this.updateRoutingCache();
        //this.removeRouteCache(tab.route);
    }


    private updateRoutingCache() {
        if ((<TabRouteStrategy>this.routeReuse)['tabs'] !== undefined) {
          (<TabRouteStrategy>this.routeReuse).tabs = this.tabs;
        }
      }

      private removeRouteCache(path: string) {
        if ((<TabRouteStrategy>this.routeReuse)['removeRoute'] !== undefined) {
          (<TabRouteStrategy>this.routeReuse).removeRoute(path);
        }
      }



}
