<!--<div class="avatar">-->

<!--    <div class="select">-->
<!--        <button mat-icon-button-->
<!--                (click)="selectImage()">-->
<!--            <mat-icon>camera</mat-icon>-->
<!--        </button>-->
<!--    </div>-->

<!--    <div class="remove">-->
<!--        <button mat-icon-button-->
<!--                (click)="clearImage()">-->
<!--            <mat-icon>delete</mat-icon>-->
<!--        </button>-->
<!--    </div>-->

<!--    <img *ngIf="hasImage"-->
<!--         [src]="image?.url"-->
<!--         #avatar>-->

<!--    <div class="no-avatar"-->
<!--         *ngIf="!hasImage">-->
<!--        SELECT IMAGE-->
<!--    </div>-->

<!--</div>-->
<div id="no-image-wrapper" [style.height]="imageHeight + 'px'" [style.width]="imageWidth + 'px'" *ngIf="!hasImage" >
    <button mat-icon-button [disableRipple]="true" class="left" (click)="uploadImage()"><mat-icon>camera</mat-icon></button>
    <button mat-icon-button [disableRipple]="true" class="right" (click)="clearImage()"><mat-icon>delete</mat-icon></button>
</div>

<div id="image-wrapper" [style.background-image]="'url(' + image?.url + ')'" [style.height]="imageHeight + 'px'" [style.width]="imageWidth + 'px'" *ngIf="hasImage">
    <button mat-icon-button class="left" (click)="uploadImage()"><mat-icon>camera</mat-icon></button>
    <button mat-icon-button class="right" (click)="clearImage()"><mat-icon>delete</mat-icon></button>
</div>
