<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"></span>
        <button mat-icon-button aria-label="Close dialog" (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
<div *ngIf="!isUploading" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
    <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
    <p class="upload-text">Click To Upload</p>
</div>

<div *ngIf="!isUploading">
    <div class="files-list" *ngFor="let file of files;let i= index">
        <p> {{ file }} </p>
        <button class="delete-file" (click)="deleteAttachment(i)">
            <img src="/assets/images/recycle_bin.png">
        </button>
    </div>
</div>

<mat-spinner *ngIf="isUploading" class="spinner" [mode]="'indeterminate'" [value]="100"></mat-spinner>
