import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageField } from '@app/core/app-services/content-services';
import { MediaSelectorService } from '../media-selector.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

    @Input() image: ImageField;
    @Input() imageHeight: number;
    @Input() imageWidth: number;
    @Output() imageChange = new EventEmitter<ImageField>();

    constructor(private _imageSelector: MediaSelectorService) { }

    ngOnInit(): void {
    }


    get hasImage(): boolean {
        return this.image !== undefined && this.image.id !== 0;
    }

    async selectImage(): Promise<void> {
        try {
            const result = await this._imageSelector.selectImage();

            if (result.isCompleted) {
                const image = new ImageField();
                image.id = result.value.id;
                image.url = result.value.url;
                this.image = image;


                this.imageChange.emit(image);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async uploadImage(): Promise<void> {
        try {
            const result = await this._imageSelector.uploadImage();
            if (result.isCompleted) {
                const image = new ImageField();
                image.id = result.value.imageAssetId;
                image.url = result.value.imageUrl;
                this.image = image;


                this.imageChange.emit(image);
            }
        } catch (e) {
            console.log(e);
        }
    }


    async clearImage(): Promise<void> {

        const image = new ImageField();
        image.id = 0;
        image.url = '';
        this.image = image;
        this.imageChange.emit(image);


    }

}
