import {Component, OnInit, ViewChild} from '@angular/core';
import {FileParameter, IAddImageAssetResponse, ImagesApiProxy} from '@core/app-services/app-services';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogResult} from '@shared/utilities/dialogs/dialog.model';
import {ProblemEditorResult} from '@modules/quiz/designer/editors/problem-editor.model';
import {MultiChoiceProblem} from '@core/app-services/content-services';

@Component({
    templateUrl: './image-uploader.dialog.html',
    styleUrls: ['./image-uploader.dialog.scss'],
})
export class ImageUploaderDialog implements OnInit {
    files: any = [];
    isUploading = false;
    constructor(private _imagesApi: ImagesApiProxy, public dialogRef: MatDialogRef<ImageUploaderDialog, DialogResult<any>>,) {

    }

    ngOnInit(): void {
    }


    async uploadFile(event) {
        this.isUploading = true;
        for (let index = 0; index < event.length; index++) {
            const element = event[index];

            const file: FileParameter = { data: element, fileName: element.name };

            await this._imagesApi.addImage(file)
                .subscribe((data) => {
                        this.dialogRef.close(DialogResult.completed(data));
                        this.isUploading = false;
                    },
                    (error) => {
                        this.files.splice(0, 1);
                        this.isUploading = false;
                    });

            this.files.push(element.name)
        }
    }

    deleteAttachment(index) {
        this.files.splice(index, 1)
    }

    cancel(): void {
        this.dialogRef.close(new DialogResult());
    }

}
