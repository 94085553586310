export class DialogResult<T = any>{


    constructor(public value: T = null, public isCompleted = false)
    {

    }

    static completed<T>(value: T): DialogResult<T> {
       return new DialogResult<T>(value, true);
    }

    static cancelled<T>(): DialogResult<T> {
        return new DialogResult<T>();
    }
}

export enum DialogSize {
    Small = 'Small' as any,
    Medium = 'Medium' as any,
    Large = 'Large' as any,
}

