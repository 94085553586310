<!-- Button -->
<button class="icon-button"
        mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="avatar">

        <mat-icon [svgIcon]="'account_circle'"></mat-icon>

    </span>
</button>

<mat-menu class="user-actions-menu"
          [xPosition]="'before'"
          #userActions="matMenu">
    <button mat-menu-item
            (click)="signOut()">
        <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu"
          #userStatus="matMenu">
    <button mat-menu-item
            (click)="updateUserStatus('online')">
        <span class="status online"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('away')">
        <span class="status away"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('busy')">
        <span class="status busy"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('not-visible')">
        <span class="status not-visible"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
