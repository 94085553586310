import { Injectable } from '@angular/core';
import { TreoNavigationItem } from '@treo/components/navigation';
import { BehaviorSubject, Subject } from 'rxjs';
import { PageInfo } from './page-info';

@Injectable({
  providedIn: 'root'
})
export class ShellService {


  private readonly _navigation = new BehaviorSubject<TreoNavigationItem[]>([]);
  navigation$ = this._navigation.asObservable();
  get navigation() {
    return this._navigation.value;
  }
  navigationTitle: string = 'COURSES';


  titleChanged: Subject<PageInfo> = new Subject<PageInfo>();

  constructor() { }

  setNavigation(title: string, items: TreoNavigationItem[]) {
    this.navigationTitle = title;
    this._navigation.next(items);
  }

  setTitle(title: string, subTitle?: string, icon?: string) {
      let info = new PageInfo();
      info.title = title;
      info.subTitle = subTitle;
      info.icon = icon;
     this.titleChanged.next(info);
  }
}
