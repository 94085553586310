import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { TreoModule } from '@treo';
import { TreoConfigModule } from '@treo/services/config';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockDataServices } from 'app/data/mock';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor, RefreshTokenService } from 'abp-ng2-module';
import { AppInitializer } from './app-initializer';
import { APP_API_BASE_URL } from '@app/core/app-services/app-services';
import * as content from '@app/core/app-services/content-services';
import * as registry from '@app/core/app-services/registry-services';
import { AppConsts } from './shared/app-consts';
import { AbpCoreModule } from './core/abp/abp-core.module';
import { AppServicesModule } from './core/app-services/app-services.module';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MediaModule } from './shared/media/media.module';
import { AuthRefreshTokenService } from './core/auth/auth-refresh-token.service';
import { UtilsModule } from '@shared/utilities/utils.module';
const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled'
};

export function getCurrentLanguage(): string {
    if (abp.localization.currentLanguage.name) {
      return abp.localization.currentLanguage.name;
    }

    // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
    return 'en';
  }

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockDataServices),

        // App Services
        AppServicesModule.forRoot(),
        AbpCoreModule.forRoot(),


        UtilsModule,

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        //Material
        MatDialogModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),

        ReactiveFormsModule,

         // Material moment date module
         MatMomentDateModule,

        FormlyModule.forRoot(),



        FormlyMaterialModule,



        MediaModule,


    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
          deps: [AppInitializer],
          multi: true,
        },
        { provide: APP_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        { provide: content.CONTENT_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        { provide: registry.REGISTRY_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        {
          provide: LOCALE_ID,
          useFactory: getCurrentLanguage,
        },
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
            hasBackdrop: false,
            panelClass: 'dialog-panel'
        }},
        { provide: RefreshTokenService, useClass: AuthRefreshTokenService}
      ],
})
export class AppModule
{
}
