import { ActivityType } from './activity-type';

export const ACTIVITY_TYPES: ActivityType[] = [
    {
        name: 'Presentation',
        createRoute: '/presentation',
        editRoute: '/presentation',
        icon: 'media'
    },
    {
        name: 'Quiz',
        createRoute: '/quiz',
        editRoute: '/quiz',
        icon: 'media'
    },
    {
        name: 'Recording',
        createRoute: '/recording',
        editRoute: '/recording',
        icon: 'media'
    }

]
