import { Injectable } from '@angular/core';
import {
    ActivityDto,
    CourseDto,
    CourseApiProxy,
    LessonApiProxy,
    ActivityApiProxy,
    CourseDetailsDto,
    SubjectDto,
    AssignmentTypeDto,
    AccessLevelDto, CourseSegmentApiProxy, CourseSegmentDto, ReorderSegmentInput, ActivitySequence
} from '@core/app-services/content-services';
import { Router } from '@angular/router';
import { ACTIVITY_TYPES } from '@core/courses/activity-types';
import { ActivityType } from '@core/courses/activity-type';
import { DialogService } from '@shared/utilities/dialogs/dialog.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class CoursesService {

    courseId: number | undefined;
    private _course = new BehaviorSubject<CourseDto>(null);
    public readonly course$ = this._course.asObservable();
    get course() {
        return this._course.value;
    }
    courses: CourseDto[] = [];
    courseDetails: CourseDetailsDto | undefined;
    activityTypes: ActivityType[] = [];
    subjects: SubjectDto[] = [];
    assignmentTypes: AssignmentTypeDto[] = [];
    private _segments = new BehaviorSubject<CourseSegmentDto[]>([]);
    public readonly segments$ = this._segments.asObservable();
    constructor(private courseApi: CourseApiProxy,
        private segmentApi: CourseSegmentApiProxy,

        private router: Router) {
        this.activityTypes = ACTIVITY_TYPES;
    }



    async getCourses(): Promise<CourseDto[]> {

        const result = await this.courseApi.getMemberCourses().pipe(take(1)).toPromise();

        this.courses = result;

        return this.courses;

    }

    async getAssignedCourses(): Promise<CourseDto[]> {

        const result = await this.courseApi.getMemberCourses().pipe(take(1)).toPromise();

        this.courses = result;

        return this.courses;

    }

    async getCourse(courseId: number): Promise<CourseDetailsDto> {

        if (!this.courseId || (this.courseId != courseId)) {
            await this.loadCourse(courseId)
        }
        return this.courseDetails;


    }

    async loadCourse(courseId: number): Promise<CourseDetailsDto> {

        this.courseId = courseId;
        const result = await this.courseApi.getCourseDetail(courseId).pipe(take(1)).toPromise();
        this._course.next(result.course);
        this.subjects = result.subjects;
        this.assignmentTypes = result.assignmentTypes;

        let segments = await this.segmentApi.getCourseSegments(this.courseId).pipe(take(1)).toPromise();
        let orderedSegments = this.orderSegments(segments);
        this._segments.next(orderedSegments);
        this.courseDetails = result;

        return result;
    }

    async getSegments(courseId: number): Promise<CourseSegmentDto[]> {

        var segments = await this.segmentApi.getCourseSegments(courseId).pipe(take(1)).toPromise();
        let orderedSegments = this.orderSegments(segments);
        return orderedSegments;


    }

    async getAccessLevels(): Promise<AccessLevelDto[]> {

        return this.courseDetails.accessLevels;


    }

    getSegment(segmentId: number): CourseSegmentDto {
        return this._segments.value.find(n => n.id == segmentId);
    }

    orderSegments(segments: CourseSegmentDto[], latestFirst: boolean = true): CourseSegmentDto[] {

        if (latestFirst) {
            return segments.sort((a, b) => (b.sectionNumber * 100 + b.segmentNumber) - (a.sectionNumber * 100 + a.segmentNumber));
        } else {
            return segments.sort((a, b) => (a.sectionNumber * 100 + a.segmentNumber) - (b.sectionNumber * 100 + b.segmentNumber));
        }
    }




    async getAssignmentTypes(courseId: number) {
        const result = await this.courseApi.getCourseDetail(courseId).pipe(take(1)).toPromise();

        return result.assignmentTypes;
    }

    async addSegment(segment: CourseSegmentDto): Promise<void> {


        const dto = await this.segmentApi.create(segment).pipe(take(1)).toPromise();

        var segments = this._segments.value;

        segments.push(dto);

        let orderedSegments = this.orderSegments(segments);
        this._segments.next(orderedSegments);

    }

    async updateSegment(segment: CourseSegmentDto): Promise<void> {


        const dto = await this.segmentApi.update(segment).pipe(take(1)).toPromise();

        var segments = this._segments.value;

        segments = segments.map(n => n.id == segment.id ? dto : n);

        let orderedSegments = this.orderSegments(segments);
        this._segments.next(orderedSegments);

    }






}
