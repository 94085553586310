import { Injectable } from '@angular/core';
import { RefreshTokenResult, TokenAuthApiProxy } from '@app/core/app-services/app-services';
import { AppConsts } from '@app/shared/app-consts';
import { LocalStorageService } from '@shared/utilities/local-storage.service';


import { RefreshTokenService, TokenService } from 'abp-ng2-module';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthRefreshTokenService implements RefreshTokenService {
  constructor(
    private _tokenAuthService: TokenAuthApiProxy,
    private _tokenService: TokenService,
    private _localStorageService: LocalStorageService
  ) { }

  tryAuthWithRefreshToken(): Observable<boolean> {
    let refreshTokenObservable = new Subject<boolean>();

    let token = this._tokenService.getRefreshToken();
    if (!token || token.trim() === '') {
      return of(false);
    }

    this._tokenAuthService.refreshToken(token)
      .subscribe(
        (tokenResult: RefreshTokenResult) => {
          if (tokenResult && tokenResult.accessToken) {
            let tokenExpireDate = (new Date(new Date().getTime() + 1000 * tokenResult.expireInSeconds));
            this._tokenService.setToken(tokenResult.accessToken, tokenExpireDate);

            this._localStorageService.setItem(AppConsts.authorization.encryptedAuthTokenName,
              {
                  token: tokenResult.encryptedAccessToken,
                  expireDate: tokenExpireDate
              });

            refreshTokenObservable.next(true);
          } else {
            refreshTokenObservable.next(false);
          }
        },
        (error: any) => {
          refreshTokenObservable.next(false);
        }
      );
    return refreshTokenObservable;
  }
  
}