import { Injectable } from '@angular/core';
//import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@app/core/abp/app-session.service';

import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild
} from '@angular/router';
import { PermissionCheckerService } from 'abp-ng2-module';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let redirectUrl = state.url;

        if ( redirectUrl === '/sign-out' )
        {
            redirectUrl = '/';
        }

        if (!this._sessionService.user) {
            this.goToSignIn(redirectUrl);
            return false;
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }

        this.goToSignIn(redirectUrl);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/auth/login';
        }

        if (this._permissionChecker.isGranted('Pages.Users')) {
            return '/app/admin/users';
        }

        return '/apps';
    }

    private goToSignIn(redirectURL){
        // Redirect to the sign-in page
        this._router.navigate(['sign-in'], {queryParams: {redirectURL}});

       
    }
}
