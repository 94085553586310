import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthenticateModel, AuthenticateResultModel, TokenAuthApiProxy } from '../app-services/app-services';
import { AppConsts } from '@app/shared/app-consts';
import { UtilsService, TokenService, LogService } from 'abp-ng2-module';
import { UrlHelper } from '@app/shared/abp/helpers/url-helper';
import { LocalStorageService } from '@shared/utilities/local-storage.service';

@Injectable()
export class AuthService
{
    authenticateModel: AuthenticateModel;
    authenticateResult: AuthenticateResultModel;
    rememberMe: boolean;
    // Private
    private _authenticated: boolean;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _tokenAuthService: TokenAuthApiProxy,
        private _utilsService: UtilsService,
        private _tokenService: TokenService,
        private _logService: LogService,
        private _localStorageService: LocalStorageService,
    )
    {
        // Set the defaults
        this._authenticated = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter and getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem(AppConsts.authorization.authTokenName, token);
    }

    get accessToken(): string
    {
        return localStorage.getItem(AppConsts.authorization.authTokenName);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string }, rememberMe = false): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.userNameOrEmailAddress = credentials.email;
        this.authenticateModel.password = credentials.password;
        this.authenticateModel.rememberClient = rememberMe;

        return this._tokenAuthService.authenticate(this.authenticateModel).pipe(
            switchMap((result: AuthenticateResultModel) => {

                this.authenticateResult = result;
                if(result.accessToken) {
                // Store the access token in the local storage
                this.accessToken = result.accessToken;
                this._tokenService.setRefreshToken(result.refreshToken);
                // Set the authenticated flag to true
                this._authenticated = true;
                this.login(
                    result.accessToken,
                    result.encryptedAccessToken,
                    result.expireInSeconds,
                    result.refreshToken,
                    result.refreshTokenExpireInSeconds,
                    this.rememberMe
                );
                }

                // Return a new observable with the response
                return of(result);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    // signInUsingToken(): Observable<any>
    // {
    //     // Renew token
    //     return this._httpClient.post('api/auth/refresh-access-token', {
    //         access_token: this.accessToken
    //     }).pipe(
    //         catchError(() => {

    //             // Return false
    //             return of(false);
    //         }),
    //         switchMap((response: any) => {

    //             // Store the access token in the local storage
    //             this.accessToken = response.access_token;

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Return true
    //             return of(true);
    //         })
    //     );
    // }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');

        // Set the authenticated flag to false
        this._authenticated = false;

        this.logout();

        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }



        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    private login(
        accessToken: string,
        encryptedAccessToken: string,
        expireInSeconds: number,
        refreshToken: string,
        refreshTokenExpireInSeconds: number,
        rememberMe?: boolean,
        redirectUrl?: string
    ): void {
        const tokenExpireDate = rememberMe
            ? new Date(new Date().getTime() + 1000 * expireInSeconds)
            : undefined;

        this._tokenService.setToken(accessToken, tokenExpireDate);

        if (refreshToken && rememberMe) {
            let refreshTokenExpireDate = rememberMe
                ? new Date(
                    new Date().getTime() + 1000 * refreshTokenExpireInSeconds
                )
                : undefined;
            this._tokenService.setRefreshToken(
                refreshToken,
                refreshTokenExpireDate
            );
        }

        

        this._utilsService.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            encryptedAccessToken,
            tokenExpireDate,
            abp.appPath
        );
        let self = this;
        this._localStorageService.setItem(
            AppConsts.authorization.encryptedAuthTokenName,
            {
                token: encryptedAccessToken,
                expireDate: tokenExpireDate,
            }, ()=>{
                self.redirectToLoginResult(redirectUrl);
            }
        );

        // let initialUrl = UrlHelper.initialUrl;
        // if (initialUrl.indexOf('/login') > 0) {
        //     initialUrl = AppConsts.appBaseUrl;
        // }

        // location.href = initialUrl;
    }

    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = false;
        this.authenticateResult = null;
        this.rememberMe = false;
    }


    private logout(reload?: boolean): void {
        abp.auth.clearToken();
        abp.utils.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            undefined,
            undefined,
            abp.appPath
        );

    }

    private redirectToLoginResult(redirectUrl?: string): void {
        if (redirectUrl) {
            location.href = redirectUrl;
        } else {
            let initialUrl = UrlHelper.initialUrl;

            if (initialUrl.indexOf('/auth/sign-in') > 0) {
                initialUrl = AppConsts.appBaseUrl;
            }

            location.href = initialUrl;
        }
    }
}
