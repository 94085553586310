import { Component, OnInit, ViewChild } from '@angular/core';
import { MediaInfo } from '../media.model';
import { MediaService } from '../media.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from '@shared/utilities/dialogs/dialog.model';
import { TreeViewComponent, NodeClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { SelectableItem } from '@shared/utilities/selectable-item';
import {
    ImageMediaDto,
    MediaFolderDto,
    SearchFolderImagesRequest,
} from '@app/core/app-services/content-services';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

@Component({
    templateUrl: './image-selector.dialog.html',
    styleUrls: ['./image-selector.dialog.scss'],
})
export class ImageSelectorDialog implements OnInit {
    @ViewChild('folderTree') treeValidate: TreeViewComponent;

    folderImages: SelectableItem<ImageMediaDto>[] = [];
    mediaFolders: MediaFolderDto[] = [];
    uploadMode = false;
    tree: any;
    currentFolderId: string;
    currentFolder: MediaFolderDto;
    currentFolderName: string;
    searchTerm$ = new Subject<string>();
    searchQueryChanged: Subject<string> = new Subject<string>();
    selectedImage: ImageMediaDto|null = null;

    constructor(
        public dialogRef: MatDialogRef<ImageSelectorDialog, DialogResult<MediaInfo>>,
        private _mediaService: MediaService
    ) {
        this.searchFolderImages(this.searchTerm$)
        .subscribe(results =>{
            this.folderImages = results.map(n=>new SelectableItem(n));
        })
    }

    async ngOnInit(): Promise<void> {
        await this.loadMedia();

        const index = this.mediaFolders.findIndex(n => n.isRoot);
        if (index > -1) {
            const item = this.mediaFolders[index];
            this.currentFolder = item;

            await this.loadFolderImages(item.id);
        }
    }



    async cancel(): Promise<void> {
        this.dialogRef.close(DialogResult.cancelled());
    }

    async complete() { }

    searchFolderImages(terms: Observable<string>): Observable<ImageMediaDto[]> {


        return terms.debounceTime(400).distinctUntilChanged()
        .switchMap( (term) => {
            const request = new SearchFolderImagesRequest();
            request.searchText = term;
            request.pageNumber = 0;
            request.pageSize = 10;
            return this._mediaService.searchFolderImages(request);
        });

    }


    async loadMedia(): Promise<void> {
        this.mediaFolders = await this._mediaService.getFolderHierarchy();
        this.tree = {
            dataSource: this.mediaFolders,
            id: 'id',
            text: 'name',
            child: 'subFolders',
            expanded: 'expanded',
            selected: 'isSelected',
        };
    }

    async loadFolderImages(id: any): Promise<void> {
        abp.ui.setBusy('.container-content');
        this.folderImages = [];
        const results = await this._mediaService.getFolderImages(id, 0, 0);
        this.folderImages = results.map(n => new SelectableItem(n));
        abp.ui.clearBusy('.container-content');
    }

    async folderClicked(args: NodeClickEventArgs): Promise<void> {
        this.currentFolderId = args.node.getAttribute('data-uid');
        const folder = this.selectFolder(this.mediaFolders, this.currentFolderId);
        await this.loadFolderImages(this.currentFolderId);
        this.currentFolder = folder;
    }


    imageClicked(image: ImageMediaDto){
        this.selectedImage = null;
        this.folderImages.forEach(n=> {
            n.isSelected = n.item.id == image.id;
            if(n.isSelected){
                this.selectedImage = n.item;
            }
        })
    }

    private selectFolder(folders: MediaFolderDto[], folderId: string): MediaFolderDto {

        let item: MediaFolderDto;

        folders.forEach(n => {
            if (n.id === folderId) {
                item = n;
                return false;
            }

            if (n.subFolders) {
                const subResult = this.selectFolder(n.subFolders, folderId);

                if (subResult) {
                    item = subResult;
                    return false;
                }
            }
        });
        return item;
    }
}
