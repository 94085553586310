<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Select Image</span>
            <button mat-icon-button aria-label="Close dialog" (click)="cancel()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0" fxLayout="column" fxLayoutGap="45px" style="padding: 24px;">

        <div fxLayout="row" fxFlex="100%">

            <div class="folder-tree-sidebar">
                <ejs-treeview id='tree' #treeValidate [fields]='tree' (nodeClicked)='folderClicked($event)'>
                </ejs-treeview>
            </div>

            <div *ngIf="!uploadMode" class="image-grid">
                <!-- Card -->
                <treo-card class="flex flex-col max-w-80 w-full filter-article" *ngFor="let image of folderImages" 
                    fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" (onclick)="imageClicked(image.item)">
                    <img class="object-cover" src="{{image.item.url}}" />
                    <div class="m-8">
                        <div class="text-secondary text-md mt-1">{{image.item.fileName}}</div>
                    </div>
                </treo-card>

            </div>

            <div *ngIf="uploadMode" class="image-upload">

            </div>

        </div>

    </div>

    <div mat-dialog-actions class="m-0" fxLayout="row" style="padding: 16px;" fxLayoutAlign="end" fxLayoutGap="10px">

    </div>

</div>