import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/app-consts';
import { AuthenticateModel, AuthenticateResultModel, TokenAuthApiProxy } from '../app-services/app-services';
import { Router } from '@angular/router';
import { UtilsService, TokenService, LogService } from 'abp-ng2-module';
import { UrlHelper } from '@app/shared/abp/helpers/url-helper';
import { take } from 'rxjs/operators';

@Injectable()
export class AppAuthService {

    authenticateModel: AuthenticateModel;
    authenticateResult: AuthenticateResultModel;
    rememberMe: boolean;
    constructor(
        private _tokenAuthService: TokenAuthApiProxy,
        private _router: Router,
        private _utilsService: UtilsService,
        private _tokenService: TokenService,
        private _logService: LogService
    ) {
        this.clear();
    }

    logout(reload?: boolean): void {
        abp.auth.clearToken();
        abp.utils.setCookieValue(AppConsts.authorization.encryptedAuthTokenName, undefined, undefined, abp.appPath);

    }

    /**
     * Sign in
     *
     * @param credentials
     */
    async authenticate(credentials: { email: string, password: string }, rememberMe = false): Promise<AuthenticateResultModel>
    {


        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.userNameOrEmailAddress = credentials.email;
        this.authenticateModel.password = credentials.password;
        this.authenticateModel.rememberClient = rememberMe;
        this.rememberMe = rememberMe;
        var result = await this._tokenAuthService.authenticate(this.authenticateModel).pipe(take(1)).toPromise();

        this.processAuthenticateResult(result);

        return this.authenticateResult;


    }

    private processAuthenticateResult(authenticateResult: AuthenticateResultModel): boolean {
        this.authenticateResult = authenticateResult;

        if (authenticateResult.isSuccessful) {
            // Successfully logged in
            this.login(
                authenticateResult.accessToken,
                authenticateResult.encryptedAccessToken,
                authenticateResult.refreshToken,
                authenticateResult.expireInSeconds,
                this.rememberMe
            );
            return true;
        } else {
            // Unexpected result!

            this._logService.warn('Unexpected authenticateResult!');
            this._router.navigate(['sign-in']);
            return false;
        }
    }

    private login(
        accessToken: string,
        encryptedAccessToken: string,
        refreshToken: string,
        expireInSeconds: number,
        rememberMe?: boolean
    ): void {
        const tokenExpireDate = rememberMe
            ? new Date(new Date().getTime() + 1000 * expireInSeconds)
            : undefined;

            localStorage.setItem(AppConsts.authorization.authTokenName, accessToken);

        this._tokenService.setToken(accessToken, tokenExpireDate);
        this._tokenService.setRefreshToken(refreshToken);

        this._utilsService.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            encryptedAccessToken,
            tokenExpireDate,
            abp.appPath
        );

        let initialUrl = UrlHelper.initialUrl;
        if (initialUrl.indexOf('/login') > 0) {
            initialUrl = AppConsts.appBaseUrl;
        }

        location.href = initialUrl;
    }

    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = false;
        this.authenticateResult = null;
        this.rememberMe = false;
    }
}
