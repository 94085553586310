import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageSelectorDialog} from './image-selector/image-selector.dialog';
import {ImagePreviewComponent} from './image-preview/image-preview.component';
import {MatUiModule} from '../mat-ui/mat-ui.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ContextMenuModule, ToolbarModule, TreeViewModule} from '@syncfusion/ej2-angular-navigations';
import {MediaService} from './media.service';
import {TreoCardModule} from '@treo/components/card';
import {
    ContextMenuService,
    EditService,
    SelectionService,
    SortService,
    ToolbarService
} from '@syncfusion/ej2-angular-grids';
import {MediaSelectorService} from '@shared/media/media-selector.service';
import {ImageUploaderDialog} from '@shared/media/image-uploader/image-uploader.dialog';
import {DragDropDirective} from '@shared/directive/drag-drop.directive';
import {ImagesApiProxy} from '@core/app-services/app-services';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
    declarations: [
        ImageSelectorDialog,
        ImagePreviewComponent,
        ImageUploaderDialog
    ],
    entryComponents: [
        ImageSelectorDialog,
        ImageUploaderDialog,
        ImagePreviewComponent],
    imports: [
        CommonModule,
        MatUiModule,
        FlexLayoutModule,
        TreeViewModule,
        TreoCardModule,
        MatProgressSpinnerModule,
    ],
    exports: [ImagePreviewComponent],
    providers: [
        MediaSelectorService,
        ContextMenuService,
        EditService,
        SelectionService,
        SortService,
        ToolbarService,
        DragDropDirective
    ]
})
export class MediaModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MediaModule,
            providers: [
                MediaService,
            ]
        };
    }
}
