import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationItem, TreoNavigationService } from '@treo/components/navigation';
import { CoursesService } from '@app/core/courses/courses.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDetailsDto, CourseDto } from '@app/core/app-services/content-services';
import { ShellService } from '@app/core/shell/shell.service';
import { AppSessionService } from '@app/core/abp/app-session.service';
import { NavigationPermisssionService } from '@app/core/auth/navigation-permisssion.service';
import { AppPermissions } from '@app/shared/app-permissions';

@UntilDestroy()
@Component({
    selector     : 'dense-layout',
    templateUrl  : './dense.component.html',
    styleUrls    : ['./dense.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection:  ChangeDetectionStrategy.Default
})
export class DenseLayoutComponent implements OnInit
{
    data: any;
    isScreenSmall: boolean;
    navigationAppearance: 'classic' | 'dense';
    courseMenuItem: TreoNavigationItem;
    menuData: TreoNavigationItem[];

    @HostBinding('class.fixed-header')
    fixedHeader: boolean;

    @HostBinding('class.fixed-footer')
    fixedFooter: boolean;

    title: string = "Ucademy";



    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     * @param {Router} _router
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        private _router: Router,
        private _courseService: CoursesService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _shell: ShellService,
        public session: AppSessionService, private navPermissions: NavigationPermisssionService
    )
    {


        // Set the defaults
        this.fixedHeader = false;
        this.fixedFooter = false;
        this.navigationAppearance = 'dense';

        let data: TreoNavigationItem[] =

        [
            {
                id      : 'academy',
                title   : 'Academy',
                subtitle: '',
                type    : 'group',
                icon    : 'apps',
                children: [
                    {
                        id   : 'academy.home',
                        title: 'Courses',
                        type : 'basic',
                        link : '/admin',
                        icon: 'collections_bookmark'
                    },
                    {
                        id   : 'academy.students',
                        title: 'Students',
                        type : 'basic',
                        link : '/students',
                        icon: 'group',
                        permission: AppPermissions.registry.staff
                    },

                    {
                        id   : 'academy.registrations',
                        title: 'Registrations',
                        type : 'basic',
                        link : '/registration',
                        icon: 'group',
                        permission: AppPermissions.registry.staff
                    },

                ],

            },

        ];

        this.navPermissions.setupPermissions(data);
        this.menuData = data;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the resolved route data
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
            .pipe(untilDestroyed(this))
            .subscribe(({matchingAliases}) => {

                // Check if the breakpoint is 'lt-md'
                this.isScreenSmall = matchingAliases.includes('lt-md');
            });
        this._shell.navigation$
        .pipe(untilDestroyed(this))
        .subscribe(items=> {
            //this.loadCourse(items);
        })
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void
    {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(key);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void
    {
        this.navigationAppearance === 'classic' ? this.navigationAppearance = 'dense' : this.navigationAppearance = 'classic';
    }


    // loadCourse(items: TreoNavigationItem[]) {



    //     this.title = this._shell.navigationTitle;

    //     this.courseMenuItem = {
    //         title   : this.title,
    //         type    : 'group',
    //         icon    : 'assignment_ind',
    //         link: 'details',

    //       };




    //         this.courseMenuItem.children = items;

    //         this.menuData =  [
    //             {
    //                 id      : 'academy',
    //                 title   : 'Academy',
    //                 subtitle: '',
    //                 type    : 'group',
    //                 icon    : 'apps',
    //                 children: [
    //                     {
    //                         id   : 'academy.home',
    //                         title: 'Home',
    //                         type : 'basic',
    //                         link : '/academy'
    //                     },
    //                     {
    //                         id   : 'ecademy.assignments',
    //                         title: 'Assignments',
    //                         type : 'basic',
    //                         link : '/assignments'
    //                     },
    //                     // {
    //                     //     id   : 'starter.dummy.2',
    //                     //     title: 'Dummy menu item #1',
    //                     //     type : 'basic'
    //                     // }
    //                 ],

    //             },
    //             this.courseMenuItem
    //         ];

    //         this._changeDetectorRef.markForCheck();

    // }




}
